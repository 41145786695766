@import "../../base";

.contact {
  display: flex;
  flex-direction: column-reverse;
  padding: 3rem 1rem;

  .info {
    padding: 0 1.5rem;
    position: relative;

    .container {
      padding: 1.5rem 0;
    }
  }

  .form {
    padding: 0 1.5rem;
  }

  .submit {
    border-radius: $border-radius;
    padding: 5px 10px;
    margin-top: .9rem;
  }
}

.alert {
  height: 19px;
}

@media screen and (min-width: 769px) {
  .contact {
    flex-direction: row;
    justify-content: center;

    .info {
      width: calc(100vw / 3);
      max-width: 350px;
    }

    .info::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 95%;
      width: 2px;
      height: 80%;
    }

    .form {
      width: calc((100vw / 3) * 2);
      max-width: 650px;
      padding-left: 4rem;

      > input {
        margin: 10px 0;
      }
    }
  }
}