// Colors
// Used in notify component
$dark-grey: #313539;
$main-green: #308B4F;
$main-red: #8B2349;
$main-yellow: #ccc300;

// Border Radius
$border-radius: 5px;

// Max screen width
$max-width: 1200px;

// Break points
$mobile: 768px; // max-value
$tablet: 769px;
$desktop: 1024px;
$wideScreen: 1216px;
$fullHd: 1408px;