@import "../../base";

$maxHeight: 45vh;

.container {
  position: relative;
  max-height: $maxHeight;
  width: 100vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.title {
  text-align: left;
  width: fit-content;
  height: fit-content;
  margin-bottom: 5px;
}

.description {
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  width: fit-content;

  @media screen and (max-width: $mobile) {
    display: none;
  }

  @media screen and (min-width: $desktop) {
    max-width: 45vw;
  }
}

.image {
  height: $maxHeight;
  width: 100%;
  object-fit: cover;
}