.menu-btn {
    cursor: pointer;
    display: flex;
    height: 3.25rem;
    position: relative;
    width: 3.25rem;
    margin-left: auto;
    justify-content: center;
    align-items: center;
}

.menu-btn:focus {
    box-shadow: none;
}

.menu-btn__burger {
    width: 45px;
    height: 5px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 45px;
    height: 5px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all .5s ease-in-out;
}
.menu-btn__burger::before {
    transform: translateY(-13px);
}
.menu-btn__burger::after {
    transform: translateY(13px);
}
  /* ANIMATION */
.navbar-burger.is-active .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}
.navbar-burger.is-active .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
}
.navbar-burger.is-active .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
}