@import "../../../base";

.container {
  position: relative;
  overflow: hidden;
}

.video {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
}