@import "../../base";

.overview {
  margin: 0 auto;
  max-width: $max-width;

  .description {
    text-align: center;
  }
}

.allSolutions {
  padding: 20px 0 100px;
  position: relative;
}

.column {
  padding: 5px !important;
}