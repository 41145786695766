@import '../../../base';

.contactUsContainer {
  width: 90vw;
  margin: 110px auto 120px auto;
  position: relative;

  @media screen and (min-width: $tablet) {
    width: 80vw;
    max-width: $max-width;
  }

  .container {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $tablet) {
      flex-flow: column nowrap;
    }

    h3 {
      margin: 0;
      padding-bottom: 20px;
      text-align: center;
    }
  }
}