@import '../../../../base';

.solutionContainer {
  position: relative;
  height: 250px;

  .solutionText {
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 4px 15px;

    h2 {
      position: relative;
      width: fit-content;
      z-index: 10;
      word-break: keep-all;
    }

    .summaryText {
      display: block;
      font-size: 14px;
      position: relative;
      z-index: 10;
      letter-spacing: .15px;

      @media screen and (hover: none) {
        display: none;
      }
    }
  }
}

.solutionContainer::after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  filter: blur(2px);
}

.summaryTextMobile {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }

  @media screen and (hover: hover) {
    display: none;
  }

  @media screen and (hover: none) {
    font-size: 14px;
    position: relative;
    z-index: 10;
    width:100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}