@import "../../base";

.container {
  max-width: 90vw;
  margin: 0 auto;
  position: relative;

  @media(min-width: $fullHd) {
    max-width: $max-width;
    padding: 0;
  }
}