@import "../../../../base";

.container {
  width: 100%;
  height: fit-content;
  min-height: 230px;
  max-height: 500px;
  position: relative;
  margin-top: 30px;

  @media screen and (min-width: $mobile) {
    min-width: 200px;
    max-width: 350px;
    min-height: 250px;
    margin: 10px;
  }
}

.content {
  position: absolute;
  padding: 10px;
  width: 100%;
}

.text {
  position: relative;
  z-index: 10;
  margin-bottom: 0;
}

.coloredDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: .87;
  top: 0;
  left: 0;
}
