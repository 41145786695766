@import "../../../base";

.container {
  display: flex;
  flex-flow: row nowrap;
}

.quote {
  margin: 0;
  padding: 5px 0 0 8px;
  font-size: 26px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}