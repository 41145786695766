@import "../../../base";

.floatingLabelContainer {
  position: relative;
  margin-top: 25px;
}

.floatingLabel {
  position: absolute;
  bottom: -10px;
  left: 10px;
  padding: 0 5px;
  border-radius: 3px;
  font-weight: bolder;
  z-index: 1;
}