@import "../../../base";

.postContainer {
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1.5fr));
  grid-gap: 15px;
  position: relative;
  padding-top: 30px;
}

.title,
.content {
  text-align: center;
}