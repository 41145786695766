@import "../../../../base";

.careers-testimonial-card {
  padding: 20px 30px;
  border-radius: $border-radius;
  min-height: 375px;
  height: fit-content;
  margin: 10px 0 30px;
  transition: background-color, transform, box-shadow .5s ease-in-out;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .image {
    max-width: 125px;
    max-height: 125px;
    border-radius: 50%;
  }

  .name {
    padding: 20px 0 6px;
    font-weight: bold;
    font-size: 18px;
  }

  .careers-title {
    font-size: 14px;
  }

  .quote {
    text-align: center;
    padding: 20px 5px;
    font-size: 14px;
  }
}

.slick-active .careers-testimonial-card {
  position: relative;
  z-index: 1;
}


.slick-center .careers-testimonial-card {
  @media screen and (min-width: $desktop) {
    z-index: 100;
    min-height: 465px;
  }
}

