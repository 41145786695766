@import "../../../base";

.hero {
  position: relative;
  width: 100%;
  align-self: center;
  margin: 10px auto;
  padding: 20px;
}

.overviewContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .overviewLink {
    margin: 20px 0;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    font-weight: 100;
  }
}

@media screen and (min-width: $mobile) {
  .overviewContainer {
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-start;
    min-width: 90%;
    margin: 0 auto;
  }

  .tagline {
    text-align: center;
    padding: 20px 0;
    max-width: 45%;
    margin: 0 auto;
  }

  .cta {
    max-width: 45%;
    padding: 20px 0 40px;
    text-align: center;
    margin: 0 auto;
  }
}

@media screen and (min-width: $desktop) {
  .overviewContainer {
    max-width: 1400px;
  }
}