@import "../../../base";

.block {
  position: relative;
  border-radius: $border-radius;
  text-align: center;
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
}

.blockId {
  position: absolute;
  top: -85px;
}