@import "../../../base";

.contentContainer {
  max-width: 600px;
  margin: 0 auto;

  .title {
    text-align: center;
    margin: 0;
    padding: 10px 0 20px;
  }

  .content {
    padding-bottom: 20px;
    line-height: 26px;
    text-align: center;
  }
}

.sliderContainer {
  max-width: $max-width;
  margin: 0 auto;
}