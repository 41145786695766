@import "../../base";

.container {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;

  @media (min-width: $tablet) {
    flex-flow: row nowrap;
  }
}

.detail {
  width: 100%;

  @media (min-width: $tablet) {
    width: 55%;
    max-width: $max-width;
  }

  @media (min-width: $desktop) {
    width: 65%;
  }

  @media (min-width: $wideScreen) {
    width: 70%;
  }

  @media (min-width: $fullHd) {
    width: 80%;
  }
}

.master {
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 10px 20px;
  height: fit-content;
  border-radius: 5px;
  white-space: nowrap;
  position: relative;

  @media screen and (min-width: $tablet) {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
    padding: 10px 100px 10px 20px;
  }
}

.master::after {
  content: "";
  position: absolute;
  width: 15%;
  height: 4px;
  bottom: -10px;

  @media screen and (min-width: $tablet) {
    top: 0;
    right: 45px;
    width: 3px;
    height: 100%;
  }
}