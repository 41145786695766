@import "../../base.scss";

$footerToRow: $desktop;

footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  .footerBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 15px;

    @media screen and (min-width: $footerToRow) {
      flex-direction: row;
      align-items: flex-start;
      padding: 20px 0;
    }
  }
}

.footerLinks {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
}

.social {
  display: flex;
  padding-top: 5px;

  @media screen and (min-width: $footerToRow) {
    padding-top: 0;
  }

  > * {
    padding: 10px 20px;
  }
}

.copyRight {
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  * {
    font-size: 8px;
    margin: 0;
    padding: 3px;

    @media screen and (min-width: $footerToRow) {
      font-size: 12px;
      padding: 5px;
    }
  }
}