@import "../../base";

.navigation {
  position: sticky;
  z-index: 9999;
  top: 0;

  .navigationBar {
    padding: 8px 0;

    @media (min-width: $desktop) {
      padding: 8px 20px;
    }
  }

  .logoSpace {
    display: flex;
    flex-direction: column;
    text-align: center;

    .siteTagLine {
      margin: 0;
      font-size: 16px;
    }
  }
}

.navigationLink {
  display: inline-block;
  margin: 0 5px;
  text-decoration: none;
  padding: 7px 8px; // To match the contact button, you have to add the padding and border of the contact button
  

  @media (max-width: 1023px) {
    padding: 7px 15px;
    width: 100%;
    text-align: right;
  }
}

.navigationSubLink {
  display: inline-block;
  text-decoration: none;
  padding: 7px 8px; // To match the contact button, you have to add the padding and border of the contact button

  @media (max-width: 1023px) {
    //padding: 7px 15px;
    width: 100%;
    text-align: right;
  }
}

.topLevelLink {
  display: none;

  @media (min-width: 1023px) {
    display: inline-block;
  }
}

.contactButton {
  padding: 5px 13px !important;
  border-radius: $border-radius;
  text-decoration: none;
  white-space: nowrap;
  transition: all .15s ease-in-out;
}

.mobileFlex {
  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.navbarEndContainer {
  position: relative;
  padding: 0;
}

.navbarEnd {
  @media (max-width: 1023px) {
    z-index: 110;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    right: 0;

    a {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.navbarDropdown {
  padding: 0;
}

@media (min-width: $fullHd) {
  .navigationBar {
    max-width: $max-width;
  }
}

.iconButton {
  height: unset;
}