@import "../../../base";

.container {
  text-align: center;
  padding: 75px 10vw;
}

.text {
  font-size: 18px;
  line-height: 40px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: $mobile) {
    font-size: 21px;
    line-height: 45px;
  }
}