@import "../../../base";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image {
  width: 45%;
  border-radius: $border-radius;
}

.content {
  width: 50%;
}