.circleContainer {
  position: relative;
}

.image {
  position: unset;
  height: 100%;
  border-radius: 50%;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

   * {
     margin: 0;
   }
}

.title {
  text-align: center;
}

