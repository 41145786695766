@import "../../../../base";

.container {
  width: fit-content;
  max-width: 325px;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;

  .title {
    font-weight: bold;
  }

  .person {
    margin: 20px auto;
  }

  .description {
    font-size: 13px;
    text-align: center;
    padding: 0 15px 15px;
  }
}