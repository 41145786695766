@import "../../../base";

.container {
  margin: 60px 0 50px;

  @media (min-width: $desktop) {
    margin: 20px 0 50px;
  }
}

.positionContainer {
  max-width: $max-width;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-items: center;

  @media screen and (min-width: $tablet) {
    flex-flow: row wrap;
  }
}