@import "../../base";

.icon {
  margin: 0;
  font-size: 24px;
}

.arrows {
  width: 35px;
  height: 35px;
  display: block;
}