@import "../../../base.scss";

label {
    margin-bottom: unset;
}

.labeledInput {
    background-color: inherit;
    border-radius: $border-radius;
    width: 100%;
    padding: 8px 12px;
}